<template>
  <div>
    <b-card>
      <b-row style="margin: 0px;">
        <b-col cols="8">
          <strong>Katman Veri Sorgu Ekranı</strong>
        </b-col>
        <b-col cols="4">
          <b-button variant="white" @click="f_goLayerQuery('back')">
            < </b-button>
              Tarihçe ( {{ d_selectedSavedQueryListIndex !== '' ? (d_selectedSavedQueryListIndex + 1).toString() + ' / ' : '' }} {{ d_layerSaveQueryList.length }})
              <b-button variant="white" @click="f_goLayerQuery('forward')"> > </b-button>
        </b-col>
      </b-row>
      <!-- <b-row style="margin: 0px;">
        <b-col cols="12">
          <div id="layer_query" contenteditable="true" style="width: 100%; height: 150px; padding: 10px; overflow-x: hidden; overflow-y: auto; background: #ececec;"></div>
        </b-col>
      </b-row> -->
      <b-row style="margin: 0px;">
        <b-col cols="12">
          <b-textarea v-model="d_layerQuery" rows="5" style="width: 100%;"></b-textarea>
        </b-col>
      </b-row>
      <b-row style="margin: 3px;">
        <b-col cols="6"></b-col>
        <b-col cols="3" style="text-align: right;">
          <b-form-select v-model="d_queryType">
            <option value="only_algorithm">Normal</option>
            <!-- <option value="algorithm_and_mapping"> Veri Haritası </option> -->
          </b-form-select>
        </b-col>
        <b-col cols="3" style="text-align: right;">
          <b-button variant="white" @click="f_layerWdmrListByAlgorithm()" style="width: 100%; border: solid 1px #0089ff; box-shadow: -2px -2px #0089ff;">
            <img src="@/icon/1368397.png" style="width: 2em; float: left;"> SORGULA
          </b-button>
        </b-col>
      </b-row>
      <b-row style="margin: 0px;">
        <b-col cols="7">
        </b-col>
        <b-col cols="2">
          Toplam Satır: {{ d_totalRowCount }}
        </b-col>
        <b-col cols="3">
          <template v-if="d_totalRowCount > d_tablePagination['perpage']">
            <b-pagination size="sm" v-model="d_tablePagination['current']" :total-rows="d_totalRowCount" :per-page="d_tablePagination['perpage']" aria-controls="dataset-table"></b-pagination>
          </template>
        </b-col>
      </b-row>
      <b-tabs>
        <b-tab @click="d_selectedTab = 'table_mode'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span>Tablo Modu</span>
          </template>
          <template v-if="d_selectedTab === 'table_mode'">
            <b-card no-body :class="[d_fullScreenOperationPage ?'full-screen-mode' :'normal-mode']">
              <b-card-header class="p-1">
                <b-row>
                  <b-col sm="12" lg="10">
                  </b-col>
                  <b-col sm="12" lg="1">
                    <b-button class="pull-right" size="md" :variant="d_fullScreenOperationPage ? 'warning' : 'white'" :style="d_fullScreenOperationPage ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenOperationPage ? d_fullScreenOperationPage = false : d_fullScreenOperationPage = true">
                      <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row style="margin: 0px;">
                <b-col cols="12">
                  <div id="case_table" :style="f_dataSetStyle(330)">
                    <w-table key="data_table" :p_dataset="d_dataset" :p_columnList="d_columnList" :f_calculateRowNoStyle="f_calculateRowNoStyle" :f_calculateStyleDatasetTable="f_calculateStyleDatasetTable" :p_tablePagination="d_tablePagination" :f_selectCell="f_selectCell" :f_dblClickToCell="f_showWTableCellData"></w-table>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-tab>
        <b-tab @click="d_selectedTab = 'json_mode'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span>JSON MODU</span>
          </template>
          <template v-if="d_selectedTab === 'json_mode'">
            <b-card no-body :class="[d_fullScreenOperationPage ?'full-screen-mode' :'normal-mode']">
              <b-card-header class="p-1">
                <b-row>
                  <b-col sm="12" lg="10">
                  </b-col>
                  <b-col sm="12" lg="1">
                    <b-button class="pull-right" size="md" :variant="d_fullScreenOperationPage ? 'warning' : 'white'" :style="d_fullScreenOperationPage ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenOperationPage ? d_fullScreenOperationPage = false : d_fullScreenOperationPage = true">
                      <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row style="margin: 0px;">
                <b-col cols="12">
                  <div :style="f_dataSetStyle(330)">
                    {{ f_getJsonStringify(d_dataset) }}
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-tab>
        <b-tab @click="d_selectedTab = 'response'">
          <template slot="title">
            <img src="@/icon/665778.png" style="width: 1.5em;">
            <span>Servis Yanıtı</span>
          </template>
          <template v-if="d_selectedTab === 'response'">
            <b-card no-body :class="[d_fullScreenOperationPage ?'full-screen-mode' :'normal-mode']">
              <b-card-header class="p-1">
                <b-row>
                  <b-col sm="12" lg="10">
                  </b-col>
                  <b-col sm="12" lg="1">
                    <b-button class="pull-right" size="md" :variant="d_fullScreenOperationPage ? 'warning' : 'white'" :style="d_fullScreenOperationPage ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenOperationPage ? d_fullScreenOperationPage = false : d_fullScreenOperationPage = true">
                      <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-row style="margin: 0px;">
                <b-col cols="12">
                  <div :style="f_dataSetStyle(330)">
                    {{ f_getJsonStringify(d_responseResult) }}
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal v-if="d_showWTableCellData.show" v-model="d_showWTableCellData.show" size="xl" title="Hücre Değeri" centered scrollable hide-footer header-bg-variant="primary">
      <b-row>
        <b-col cols="12">
          {{ d_showWTableCellData.text }}
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LogService from '@/services/log';
import WmanagerService from '@/services/wmanager';
import store from '@/store';
import moment from 'moment';
import {
  default as WTable
} from '@/components/widgets/WTable';
// import {
//   default as Modal
// } from '@/components/widgets/Modal';

export default {
  name: 'LayerQuery',
  components: {
    WTable
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      device: 'device'
    })
  },
  data () {
    return {
      d_responseResult: {},
      d_fullScreenOperationPage: false,
      d_selectedTab: 'table_mode',
      d_queryType: 'only_algorithm',
      d_selectedSavedQueryListIndex: '',
      d_layerQuery: "",
      d_layerSaveQueryList: [],
      d_columnList: [],
      d_showSearchSettings: false,
      d_showTextFilter: false,
      d_tablePagination: { 'current': 1, 'perpage': 100, 'start': 0, 'end': 100 },
      d_searchText: '',
      user: {},
      d_startingDate: '',
      d_endingDate: '',
      d_selectedLogTypes: ['error', 'information'],
      d_showMode: true,
      d_allLogTypes: [{ 'value': 'error', 'label': 'Error' }, { 'value': 'information', 'label': 'Information' }],
      d_totalRowCount: 0,
      d_dataset: [],
      d_selectedCells: [],
      d_showWTableCellData: { 'text': '', 'show': false }
    }
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_startingDate = moment(new Date()).format('YYYY-MM-DD');
    this.d_endingDate = moment(new Date()).format('YYYY-MM-DD');
    let x = JSON.parse(localStorage.getItem('layer_query_list'));
    if (x) {
      this.d_layerSaveQueryList = x;
    }
    let lq = localStorage.getItem('layer_query');
    if (lq) {
      this.d_layerQuery = lq;
    }
    this.f_prepareTableDataset();
  },
  mounted: function () {},
  destroyed () {},
  methods: {
    f_getJsonStringify: function (data) {
      return JSON.stringify(data, null, 4);
    },
    f_goLayerQuery: function (type) {
      if (type === 'back') {
        if (this.d_selectedSavedQueryListIndex === '') {
          this.d_selectedSavedQueryListIndex = this.d_layerSaveQueryList.length - 1;
        } else {
          if (this.d_selectedSavedQueryListIndex !== 0) {
            this.d_selectedSavedQueryListIndex -= 1;
          }
        }
      } else if (type === 'forward') {
        if (this.d_selectedSavedQueryListIndex === '') {
          this.d_selectedSavedQueryListIndex = 0;
        } else {
          if (this.d_selectedSavedQueryListIndex !== this.d_layerSaveQueryList.length - 1) {
            this.d_selectedSavedQueryListIndex += 1;
          }
        }
      }
      this.d_layerQuery = this.d_layerSaveQueryList[this.d_selectedSavedQueryListIndex];
    },
    f_prepareTableDataset: function (lqd = '') {
      // console.log("this.d_tablePagination.start ", this.d_tablePagination.start);
      // console.log("this.d_tablePagination.end", this.d_tablePagination.end);
      if (lqd === '') {
        lqd = JSON.parse(localStorage.getItem('layer_query_data'));
      }
      if (lqd && lqd.length > 0) {
        this.d_columnList = [{
          'value': 'id',
          'label': 'id'
        }, {
          'value': 'client_id',
          'label': 'Client ID'
        }, {
          'value': 'label',
          'label': 'Label'
        }, {
          'value': 'date',
          'label': 'Date'
        }];
        for (let field_index in lqd[0]['fields']) {
          let field_label = '';
          if (lqd[0]['fields'][field_index]['label']) {
            field_label = lqd[0]['fields'][field_index]['label'];
          }
          let x = {
            'value': field_index,
            'label': field_label + ' [ ' + field_index + ' ]'
          };
          this.d_columnList.push(x);
        }
        this.d_totalRowCount = lqd.length;
        this.d_dataset = [];
        for (let row_ind in lqd) {
          if (parseInt(row_ind) >= this.d_tablePagination.start && parseInt(row_ind) < this.d_tablePagination.end) {
            let row_data = lqd[row_ind];
            // First we prepare column list.
            // we prepare dataste now.
            let row_new = { 'id': '', 'client_id': '', 'label': '', 'date': '' };
            if (row_data.id !== undefined) {
              row_new.id = row_data.id;
            }
            if (row_data.client_id !== undefined) {
              row_new.client_id = row_data.client_id;
            }
            if (row_data.label !== undefined) {
              row_new.label = row_data.label;
            }
            if (row_data.date !== undefined && row_data.date !== '') {
              row_new.date = moment(row_data.date).format('DD/MM/YYYY HH:mm');
            }
            for (let field_index in row_data['fields']) {
              let field_cell_text = '';
              let field_data_list = row_data['fields'][field_index]['data'];
              for (let field_data_index in field_data_list) {
                let field_data = field_data_list[field_data_index];
                for (let li_index in field_data['list']) {
                  let list_data = field_data['list'][li_index];
                  if (list_data.val) {
                    if (list_data.val.label !== undefined) {
                      field_cell_text += list_data.val.label;
                    } else {
                      field_cell_text += list_data.val;
                    }
                    if (parseInt(li_index) !== field_data['list'].length - 1) {
                      field_cell_text += ',';
                    }
                  }
                }
                if (parseInt(field_data_index) !== field_data_list.length - 1) {
                  field_cell_text += ',';
                }
              }
              row_new[field_index] = field_cell_text;
            }
            this.d_dataset.push(row_new);
          }
          if (parseInt(row_ind) == this.d_tablePagination.end) {
            break;
          }
        }
      }
    },
    f_layerWdmrListByAlgorithm: function () {
      // let layer_query = document.getElementById('layer_query').innerHTML;
      let data = {
        'sql': this.d_layerQuery
      };
      // console.log(this.d_layerQuery);
      try {
        localStorage.removeItem('layer_query');
      } catch (err) {}
      try {
        localStorage.removeItem('layer_query_data');
      } catch (err) {}
      this.d_dataset = [];
      this.d_columnList = [];
      this.d_selectedSavedQueryListIndex = '';
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Sorgu çalıştırılıyor. Lütfen bekleyiniz.' } });
      if (this.d_queryType === 'only_algorithm') {
        WmanagerService.layer_wdmr_list_by_algorithm(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            // console.log(resp);
            this.d_responseResult = resp.data;
            if (resp.data.status_code === '3000') {
              this.f_saveLayerQueryList();
              localStorage.setItem('layer_query', this.d_layerQuery);
              this.d_totalRowCount = resp.data.list.length;
              localStorage.setItem('layer_query_data', JSON.stringify(resp.data.list));
              this.f_prepareTableDataset(resp.data.list);
            } else {
              alert(resp.data.status_code + ' / ' + resp.data.status_message);
            }
          });
      } else if (this.d_queryType === 'algorithm_and_mapping') {
        WmanagerService.layer_wdmr_list_by_algorithm_and_use_mapping(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            // console.log(resp);
            if (resp.data.status_code === '3000') {
              this.f_saveLayerQueryList();
              localStorage.setItem('layer_query', this.d_layerQuery);
              this.d_totalRowCount = resp.data.list.length;
              localStorage.setItem('layer_query_data', JSON.stringify(resp.data.list));
              this.f_prepareTableDataset(resp.data.list);
            } else {
              alert(resp.data.status_code + ' / ' + resp.data.status_message);
            }
          });
      }
    },
    f_saveLayerQueryList: function () {
      if (this.d_layerSaveQueryList.length === 100) {
        this.d_layerSaveQueryList.splice(0, 1);
      }
      if (this.d_layerSaveQueryList.length > 0) {
        if (this.d_layerSaveQueryList[this.d_layerSaveQueryList.length - 1] !== this.d_layerQuery) {
          this.d_layerSaveQueryList.push(this.d_layerQuery);
        }
      } else {
        this.d_layerSaveQueryList.push(this.d_layerQuery);
      }
      localStorage.setItem('layer_query_list', JSON.stringify(this.d_layerSaveQueryList));
    },
    f_showWTableCellData: function (row, col_value) {
      this.d_showWTableCellData.text = row[col_value];
      this.d_showWTableCellData.show = true;
    },
    f_selectCell: function (row_ind, col_ind, type) {
      if (type === 'cell') {
        let select = row_ind.toString() + '-' + col_ind.toString();
        if (this.d_selectedCells.indexOf(select) === -1) {
          this.d_selectedCells.push(select);
        } else {
          this.d_selectedCells.splice(this.d_selectedCells.indexOf(select), 1);
        }
      } else if (type === 'row') {
        for (let c in this.logListColumns) {
          let y = row_ind.toString() + '-' + c.toString();
          this.d_selectedCells.push(y);
        }
      } else if (type === 'col') {
        for (let r in this.d_dataset) {
          let y = r.toString() + '-' + col_ind.toString();
          this.d_selectedCells.push(y);
        }
      }
      this.$forceUpdate();
    },
    f_dataSetStyle: function (x) {
      if (this.d_fullScreenOperationPage) {
        let height = window.innerHeight - 50;
        let overflow_x = 'auto';
        return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x:' + overflow_x + '; margin-bottom: 0px; border: none; white-space: pre;';
      } else {
        let height = window.innerHeight - x;
        let overflow_x = 'auto';
        return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x:' + overflow_x + '; margin-bottom: 0px; border: none; white-space: pre;';
      }
    },
    f_calculateStyleDatasetTable: function (row_ind, col_ind) {
      let style = '';
      style = 'white-space: nowrap; border-right: solid 1px #c1c7cc; width: 150px;';
      if (this.d_selectedCells.indexOf(row_ind.toString() + '-' + col_ind.toString()) !== -1) {
        style += 'background-color: #c6fcd8;';
      } else {
        style += 'background-color: white;';
      }
      return style;
    },
    f_calculateRowNoStyle: function (row_ind) {
      let style = '';
      let w = '60px';
      style = 'position: sticky; left: 0; width: ' + w + '!important; border-right: solid 1px #c3d8d8; cursor: pointer; background-color: white; font-size: 10px; color: black';
      return style;
    },
    f_showSearchSettings: function () {
      this.d_showSearchSettings = true;
    },
    f_showTextFilter: function () {
      this.d_showTextFilter = true;
    }
  },
  watch: {
    'd_searchText': function () {
      let text = this.d_searchText;
      setTimeout(function () {
        if (text === this.d_searchText) {
          //
        }
      }.bind(this), 2000);
    },
    'd_tablePagination.current': function () {
      this.d_tablePagination.start = (this.d_tablePagination.current - 1) * this.d_tablePagination.perpage;
      this.d_tablePagination.end = (this.d_tablePagination.perpage * this.d_tablePagination.current) - 1;
      if (this.d_tablePagination.current > 1) {
        this.d_tablePagination.end += 1;
      } else if (this.d_tablePagination.current === 1) {
        this.d_tablePagination.end = this.d_tablePagination.perpage;
      }
      this.f_prepareTableDataset();
    }
  }
}

</script>

